import React from 'react'

const BTextA = props => (
  <div className="bText1">
    <div className="BHeader1">{props.BHeader1}</div>
    <div className="BText1">{props.BText1}</div>
  </div>
)

export default BTextA
