import React from 'react'

const TextLi = props => (
  <div className="textli">
    <div className="TextLi">
      <li>{props.TextLi}</li>
    </div>
  </div>
)

export default TextLi
