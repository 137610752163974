import React from 'react'

const BTextB = props => (
  <div className="bText2">
    <div className="BHeader2">{props.BHeader2}</div>
    <div className="BText2">{props.BText2}</div>
  </div>
)

export default BTextB
